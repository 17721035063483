<template>
    <iframe :src="`${baseUrl}/api/pages/${$route.params.id}`" style="width: 100%; height: 100%; overflow: auto;"></iframe>
</template>
    
  <script>
  import { reqGetPage } from '@/api/guest/page'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  export default {
    name: 'WebBuilder',
    data() {
      return {
        data: {}
      }
    },
    mounted() {
        this.getPage()
    },
    computed: {
      baseUrl(){
        return process.env.VUE_APP_BASE_API_URL;
      }
    },
    methods: {
      getPage() {
        reqGetPage(this.$route.params.id).then(response => {
          this.data = response.data.data
        })
      },
      showToast(variant, icon, title, message) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title,
            icon,
            text: message,
            variant,
          },
        })
      },
    }
  }
  </script>